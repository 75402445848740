/* html {
  background-image: url(./images/casino-carpet-trimmed.png);
  background-repeat: repeat;
  background-size: 20vw 20vw;
  background-position: center;
  overflow-y: hidden;
  overflow-x: hidden;
  min-width: 800px;
} */

.desktop {
  display: none;
}

.rotate {
  display: none;
}

.roulette-app {
    /* width: 100%; */
    /* margin: 0px; */
    background-image: url("../../assets/images/background/bg-1.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .roulette-app-header {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5vh;
    height: auto;
  }
  
  .wheel-history {
    display: flex;
    width: 63vw;
    background-color: rgba(167, 121, 51, 0.6);
    border-radius: 14pt;
    padding-left: 0.5vw;
    margin-left: .3vw;
    font-family: 'Condiment', cursive;
    font-size: 1.7vw;
    align-items: center;
    font-weight: bold;
    color: rgb(8, 8, 8);
  }
  
  .history-num {
    margin: 0px 5px;
    color: rgb(238, 238, 238);
  }
  
  .green-previous-number {
    background-color: rgba(89, 161, 34, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .red-previous-number {
    background-color: rgba(254, 0, 0, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .black-previous-number {
    background-color: rgba(22, 22, 22, 0.9);
    width: 2.45vw;
    height: 2.45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    border-radius: 10pt;
    padding: .2vh;
    font-size: 1.6vw;
    margin-bottom: 1.2vh;
    margin-left: .1vw;
  }
  
  .header-h3 {
    margin: .4vw;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  
  .header-div {
    display: flex;
    align-items: flex-start;
    width: 13vw;
    /* height: 10vh; */
    height: 100%;
    background: linear-gradient(0.25turn, rgb(167,120,51), rgba(167, 121, 51, 0.6));
    text-shadow: rgb(167,120,51) 2px 2px 1px;
    border-radius: 14pt;
    padding-left: 0.5vw;
    margin-left: .3vw;
    font-family: 'Condiment', cursive;
    font-size: 2.5vw;
    color: rgb(12, 12, 12);
  }
  
  .right-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    background: linear-gradient(0.75turn, rgb(167,120,51), rgba(167, 121, 51, 0.6));
    margin-right: .3vw;
    width: 21vw;
  }
  
  .wood-railing {
    width: 98.4vw;
    height: 58vh;
    /* padding-top: 5vh; */
    background-image: url(https://data.1freewallpapers.com/download/polished-wood-texture.jpg);
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    border: rgb(78, 9, 1) 4px solid; 
    border-radius: 14pt;
    /* justify-self: center; */
    /* align-self: center; */
  }
  
  .chip-count-text {
    color: rgb(238, 238, 238);
    text-shadow: rgb(167,120,51) 2.5px 1px 3px;
    font-size: 2.5vw;
    /* font-family: 'Libre Baskerville', serif; */
    margin-bottom: .9vh;
  }
  
  .previous-numbers-div {
    display: flex;
  }
  
  
  .chip-count-pending-bet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: .2vw;
  }
  
  .pending-bet-span {
    font-size: 1.2vw;
    color: rgb(238, 238, 238);
    align-self: flex-end;
    margin-right: 3vw;
    position: absolute;
    right: 2vw;
    bottom: 0vmin;
  }
  
  .empty-pending-bet-span {
    font-size: 1vw;
    color: rgba(240, 248, 255, 0);
    /* position: absolute;
    right: 8vw; */
  }
  
  // @media only screen and (min-width: 300px) and (max-width: 767px) {
  //   html {
  //     background-size: 100px 100px;
  //     min-width: 300px;
  //   }
    
  //   .wood-railing {
  //     width: 70vh;
  //     height: 96vw;
      
  //     margin-bottom: 0vh;
  //     border: rgb(78, 9, 1) 4px solid; 
  //     border-radius: 14pt;
  //     /* justify-self: center; */
  //     /* align-self: center; */
  //     transform: rotate(90deg);
  //   }
  // }
    
  @media only screen and (min-width: 375px) and (max-width: 767px) {

  }
    
  @media only screen and (min-width: 425px) and (max-width: 767px) {

  }
    
  @media only screen and (min-width: 768px) and (max-width: 1440px) {
  }

  @media only screen and (max-device-width: 812px) and (orientation: portrait) {
    // .rotate {
    //   background: black;
    //   margin-top: 60px;
    //   margin-bottom: 60px;
    //   height: 200px;
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   flex-direction: column;
    // }
    
    // .phone {
    //   height: 50px;
    //   width: 100px;
    //   border: 3px solid white;
    //   border-radius: 10px;
    //   animation: rotate 1.5s ease-in-out infinite alternate;
    //   /* display: none; */
    // }
    
    // .message {
    //   color: white;
    //   font-size: 1em;
    //   margin-top: 40px;
    //   /* display: none; */
    // }
    
    // @keyframes rotate {
    //   0% {
    //     transform: rotate(0deg)
    //   }
    //   50% {
    //     transform: rotate(-90deg)
    //   }
    //   100% {
    //     transform: rotate(-90deg)
    //   }
    // }
    
    // .phone, .message {
    //   display: block;
    // }

    // .roulette-app {
    //   display: none;
    // }
  }

    // .desktop {
    //   background: black;
    //   margin-top: 60px;
    //   margin-bottom: 60px;
    //   height: 200px;
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   flex-direction: column;
    // }
    
    // .phone {
    //   height: 50px;
    //   width: 100px;
    //   border: 3px solid white;
    //   border-radius: 10px;
    //   animation: rotate 1.5s ease-in-out infinite alternate;
    //   /* display: none; */
    // }
    
    // .message {
    //   color: white;
    //   font-size: 1em;
    //   margin-top: 40px;
    //   /* display: none; */
    // }
    
    // @keyframes rotate {
    //   0% {
    //     transform: rotate(0deg)
    //   }
    //   50% {
    //     transform: rotate(-90deg)
    //   }
    //   100% {
    //     transform: rotate(-90deg)
    //   }
    // }
    
    // .phone, .message {
    //   display: block;
    // }

    // .roulette-app {
    //   display: none;
    // }

    .shadowfilter {
      -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
      filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
      }

    .shadowfilter:hover {
      -webkit-filter: drop-shadow(2px 2px 3px rgba(255, 208, 0, 0.609));
      filter: drop-shadow(2px 2px 3px rgba(254, 207, 0, 0.628));
    }
    

    .lunar-list-container {
      height: 10vh;
      margin-right: 12px;
      width: auto;
      margin-left: auto;
      margin-top: 4px;
    }
  
    .lunar-list-container:hover {
      cursor: pointer;
    }
  
    .lunar-list {
      height: 8vh;
    }

    .lunar-list:hover {
      border: 2px rgba(255, 217, 0, 0) solid;
    }