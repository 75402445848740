.game-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../images/chips/blue-felt.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 90%;
    height: 70vh;
    border-radius: 200px;
    border: rgb(27, 27, 27) 10px solid;
    margin-top: 10px;
}

.blackjack-chip-count-text {
    white-space: nowrap;
}

.dealers-cards-div {
    margin: -16px 0px -10px 0px;
}

.card-img {
    max-height: 20vh;
    width: auto;
}

.sideways-double-card {
    max-height: 20vh;
    width: auto;
    transform: rotate(90deg);
}

h1 {
    color: aliceblue;
    text-align: center;
}

/* .card-img-animation {
    animation: name duration timing-function delay iteration-count direction fill-mode;
    animation: flipInY;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
} */

.locked-bet-img {
    display: none;
    height: 10vh;
    cursor: pointer;
    border-radius: 180px;
}

.locked-bet-img:hover {
    background-color: rgb(94, 248, 23);
}

.empty-chip-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

.locked-bet-amount {
    position: absolute;
    z-index: 10;
    background-color: rgba(248, 250, 248, 0.849);
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
}

.result-message {
}

.count {
    margin: 0px 0px 0px 0px;
    height: 47px;
    font-size: 36px;
}

.chip-or-message {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gameplay-options {
    /* margin-top: 10px; */
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
    .game-div {
        /* display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../images/chips/blue-felt.jpg');
        width: 90vw; */
        height: 50vh;
        border-radius: 40px;
        /* border: rgb(27, 27, 27) 10px solid;
        margin-top: 10px; */
    }

    .card-img {
        max-height: 11vh;
        width: auto;
    }

    .count {
        margin: 0px 0px 0px 0px;
        height: 40px;
        font-size: 24px;
    }

    .result-message {
        font-size: 18px;
    }

    .sideways-double-card {
        max-height: 11vh;
    }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
    .game-div {

    }

    .card-img {
        max-height: 14vh;
    }

    .count {
        height: 28px;
        font-size: 24px;
    }

    .result-message {
        font-size: 18px;
    }

    .chip-or-message {
        height: 8vh;
        margin: 5px;
    }

    .sideways-double-card {
        max-height: 14vh;
    }

    .result-message {
        margin: 0px;
    }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {

}

@media only screen and (min-width: 721px) and (max-width: 1048px) {
    .blackjack-chip-count-text {
        font-size: 28px;
    };
}

@media only screen and (min-width: 472px) and (max-width: 720px) {
    .blackjack-chip-count-text {
        font-size: 20px;
    };
}

@media only screen and (max-device-width: 844) and (orientation: landscape) {
    .blackjack-rotate {
      background: black;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    .blackjack-phone {
      height: 50px;
      width: 100px;
      border: 3px solid white;
      border-radius: 10px;
      animation: rotate 1.5s ease-in-out infinite alternate;
      /* display: none; */
    }
    
    .blackjack-message {
      color: white;
      font-size: 1em;
      margin-top: 40px;
      /* display: none; */
    }
    
    @keyframes rotate {
      0% {
        transform: rotate(0deg)
      }
      50% {
        transform: rotate(-90deg)
      }
      100% {
        transform: rotate(-90deg)
      }
    }
    
    .blackjack-phone, .blackjack-message {
      display: block;
    }

    .blackjack-app {
      display: none;
    }
  }