body {
    // background-image: url('../../images/');
    /* background-size: cover; */
    /* overflow: hidden; */
    font-family: 'Secular One', sans-serif;
}

.blackjack-app {
    background-image: url('../../assets/images/background/bg-1.jpg');
    /* background-size: cover; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.desktop, .rotate, .locked-bet-amount, .blackjack-rotate {
    display: none;
}

.title {
    font-size: 48px;
    margin: 0px;
}

.blackjack-app-header {
    margin-top: 15px;
    width: 100%;
    /* padding-right: 10vw;
    padding-left: 10vw; */
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 3vmin;
}

p, .pending-bet-div {
    color: aliceblue;
}

.pending-bet-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    background-color: rgba(255, 255, 255, 0.205);
    padding-left: 10px;
    border-radius: 6px;
}

.blackjack-buttons {
    white-space: nowrap;
}

.game-result-div {
    height: 10vh;
    width: 90%;
    display: flex;
    justify-content: space-around;
    // padding-top: 25px;
    /* align-items: center; */
}

.betting-options {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    width: 90%;
}

.betting-option {
    border-radius: 4px;
    margin-left: 5px;
    height: 4vh;
    border: none;
    font-size: 14px;
    width: 5.3vw;
}

.inner-betting-options {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bet-button {
    height: 80px;
    // margin-bottom: 20px;
    border-radius: 60px;
    /* margin: 0px 30px; */
}

.bet-button:hover {
    background-color: rgb(169, 157, 32);
    border-radius: 60px;
    border: 2px solid gold;
    cursor: pointer;
}

.bet-button:active {
    background-color: rgb(169, 157, 32);
    border-radius: 60px;
    border: 2px solid gold;
    cursor: pointer;
}

.betting-option:focus, .reset-button:focus {
    outline:none;
}

.betting-option:active {
    border-color: transparent !important;
    outline:none;
}

.reset-button {
    margin-right: 10px;
    height: 4vh;
    border-radius: 4px;
}

.pending-bet {
    font-size: 36px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chip-count {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.205);
    padding-left: 10px;
    border-radius: 6px;
}

.chip-number {
    font-size: 48px;
    margin: 0px;
    margin-left: 5px;
    margin-right: 15px;
    width: 20vh;
    text-align: left;
}

#ready-to-start {
    background-color: rgba(7, 201, 7, 0.932);
    border: none;
    cursor: pointer;
    width: 80px;
}

#not-ready {
    color: rgb(180, 180, 180);
    width: 80px;
}

#attention-red {
    color: rgba(231, 23, 23, 0.932);
}

#normal-text {

}

@media only screen and (min-width: 300px) and (max-width: 767px) {
    .app {
        width: 100vw;
    }

    .pending-bet-div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        margin: 5px;
        background-color: rgba(255, 255, 255, 0.205);
        padding-left: 10px;
        border-radius: 6px;
        width: 90vw;
    }

    .app-header {
        margin-top: 5px;
        width: 100vw;
        flex-direction: column;
    }

    .title {
        font-size: 18px;
        margin: 0px;
    }

    .chip-number {
        font-size: 18px;
        /* margin: 0px; */
        /* margin-left: 5px; */
        /* margin-right: 15px; */
        /* width: 20vh; */
        text-align: right;
    }

    .chip-count {
        font-size: 18px;
        width: 90vw;
        /* padding: 0px; */
        justify-content: flex-start;
    }

    .pending-bet {
        font-size: 18px;
    }

    .game-result-div {
        height: max-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // padding-top: 5px;
        align-items: center;
    }

    .bet-button {
        height: 50px;
        border-radius: 60px;
        /* margin: 0px 30px; */
    }

    .betting-option {
        border-radius: 4px;
        margin-left: 5px;
        margin-top: 5px;
        height: 4vh;
        border: none;
        font-size: 14px;
        width: fit-content;
        height: auto;
    }

    .inner-betting-options {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
    .pending-bet-div {
        justify-content: space-between;
    }

    .app-header {
        margin-top: 5px;
        width: 100vw;
        flex-direction: column;
    }

    .title {
        font-size: 24px;
    }

    .chip-number {
        font-size: 18px;
        /* margin: 0px; */
        /* margin-left: 5px; */
        /* margin-right: 15px; */
        /* width: 20vh; */
        text-align: right;
    }

    .chip-count {
        font-size: 18px;
        width: 90vw;
        /* padding: 0px; */
        justify-content: flex-start;
        position: relative;
    }

    .pending-bet {
        font-size: 18px;
    }

    .game-result-div {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 5px;
        align-items: center;
    }

    .bet-button {
        height: 60px;
        border-radius: 60px;
        /* margin: 0px 30px; */
    }

    .betting-option {
        border-radius: 4px;
        margin-left: 5px;
        margin-top: 5px;
        height: 4vh;
        border: none;
        font-size: 14px;
        width: fit-content;
        height: auto;
    }

    .inner-betting-options {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .restart-button {
        position: absolute;
        right: 5px;
        bottom: 4px;
    }

    .reset-button {
        margin-right: 5px;
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .bet-button {
        height: 10vh;
        margin-bottom: 1vh;
    }
}

@media only screen and (max-width: 1260px) {
    #ready-to-start,
    #not-ready {
        width: 72px;
    }
}


@media only screen and (max-width: 756px) {
    .bet-button {
        height: 8vh;
        margin-bottom: 1vh;
    }
}

@media only screen and (max-width: 667px) {
    #ready-to-start,
    #not-ready {
        width: 60px;
    }
}

@media only screen and (max-width: 385px) {
    .bet-button {
        height:7vh;
        margin-bottom: 1vh;
    }
}

@media only screen and (max-width: 332px) {
    .bet-button {
        height:6vh;
        margin-bottom: 1vh;
    }
}

// @media only screen and (max-width: 756px) {
//     .bet-button {
//         height:8vh;
//         margin-bottom: 1vh;
//     }
// }

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    
}
// @media only screen and (max-device-width: 812px) and (orientation: portrait) {
//     .blackjack-app {
//         display: none;
//     }

//     .rotate {
//       background: black;
//       height: 100%;
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-direction: column;
//       height: 200px;
//     }
    
//     .phone {
//       height: 50px;
//       width: 100px;
//       border: 3px solid white;
//       border-radius: 10px;
//       animation: rotate 1.5s ease-in-out infinite alternate;
//       /* display: none; */
//     }
    
//     .message {
//       color: white;
//       font-size: 1em;
//       margin-top: 40px;
//       /* display: none; */
//     }
    
//     @keyframes rotate {
//       0% {
//         transform: rotate(0deg)
//       }
//       50% {
//         transform: rotate(-90deg)
//       }
//       100% {
//         transform: rotate(-90deg)
//       }
//     }
    
//     .phone, .message {
//       display: block;
//     }

//     .roulette-app {
//       display: none;
//     }
//   }

//   @media only screen and (max-device-height: 612px) and (orientation: landscape) {
//     .blackjack-app {
//         display: none;
//     }

//     .blackjack-rotate {
//         height: 200px;
//         width: 100%;
//         display: flex;
//         text-align: center;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//     }
    
//     .blackjack-phone {
//       margin: 0 auto;
//       margin-top: 100px;
//       height: 50px;
//       width: 100px;
//       border: 3px solid white;
//       border-radius: 10px;
//       animation: rotate 1.5s ease-in-out infinite alternate;
//       /* display: none; */
//     }
    
//     .blackjack-message {
//       color: white;
//       font-size: 1em;
//       margin-top: 40px;
//       /* display: none; */
//     }
    
//     @keyframes rotate {
//       0% {
//         transform: rotate(0deg)
//       }
//       50% {
//         transform: rotate(-90deg)
//       }
//       100% {
//         transform: rotate(-90deg)
//       }
//     }
    
//     .blackjack-rotate, .blackjack-phone, .blackjack-message {
//       display: block;
//     }

//     .blackjack-app {
//       display: none;
//     }
//   }