.betting-options-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 4px;
    width: 100%;
}

.chip-options-div {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 60vw;
    height: 25vh;
}

.chip-img {
    height: 10vh;
    cursor: pointer;
}

.chip-img:hover {
    border: 4px gold solid;
    border-radius: 60px;
}

.active-chip {
    border: gold 4px solid;
    cursor: pointer;
    height: 10vh;
    border-radius: 60pt;
}

.spin-circle {
    height: 14vh;
    cursor: pointer;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */
}

.spin-circle:hover {
    border: 8px gold solid;
    border-radius: 60pt;
}

.spin-circle:active {
    border: 4px gold solid;
    border-radius: 60pt;   
}

.spin-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19.5vh;
    width: 19.5vh;
}

.spin-text {
    color: rgb(238, 238, 238);
    font-family: 'Condiment', cursive;
    font-size: 4vmin;
    position: absolute;
    margin: 0px;
    text-shadow: 2px 2px 2px rgb(167,120,51);
    cursor: pointer;
}

.img-div {
    height: 13vmin;
    width: 13vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spin-img-div {
    height: 100%;
    width: 20vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
}

.buttons-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4.5vmin;
}

.filler-div {
    width: 100%;
    height: 4vmin;
}

.wheel-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.roulette-wheel {
    height: 25vh;
    border-radius: 50%;
    overflow: hidden;
}
.roulette-wheel-spinning {
    overflow: hidden;
    border-radius: 50%;
    height: 25vh;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.wheel-number-div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14.5vh;
    width: 14.5vh;
    border-radius: 100pt;
    cursor: default;
}

.winning-number-h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(240, 240, 240);
    font-size: 9vmin;
    border-radius: 60pt;
    /* padding: 1vw; */
    font-family: 'Libre Baskerville', serif;
    font-weight: 900;
    /* width: 5%;
    height: 10%; */
    text-align: center;
}

.green-wheel-number {
    background-color: rgba(89, 161, 34, 0.9);
}

.red-wheel-number {
    background-color: rgba(254, 0, 0, 0.9);

}

.black-wheel-number {
    background-color: rgba(22, 22, 22, 0.9);
}

.winnings-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(167,120,51, 0.85);
    z-index: 101;
    border-radius: 6pt;
    padding: 1vh;
    width: 100%;
    height: 100%;
    font-family: 'Condiment', cursive;
}
  
.winnings-div > h3 {
    font-size: 5.5vmin;
    color: rgb(8, 8, 8);
    text-shadow: rgb(167,120,51) 2px 2px 1px;
    text-align: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.restart-button, 
.collect-winnings-button, 
.reset-button, 
.undo-bet-button,
.same-bet-button {
    padding: 1vh;
    margin-top: 1vh;
    border-radius: 4pt;
    font-family: 'Libre Baskerville', serif;
    font-size: 0.7vw;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.restart-button:focus, 
.collect-winnings-button:focus,
.reset-button:focus, 
.undo-bet-button:focus {
    outline: none;
}

.restart-button:active, 
.collect-winnings-button:active,
.reset-button:active, 
.undo-bet-button:active {
    background-color: rgb(170, 170, 170);
    border: 1px solid rgb(167,120,51);
}

.reset-button-div {
    margin-top:36px;
    min-height: 200px;
    padding: 24px;
    display: flex;
    justify-content: center;
    position: absolute;
    width: auto;
    min-width: 35vw;
    max-width: 65vw;
}  

.winnings-div-num {
    color: rgb(238, 238, 238);
    margin-left: 1vw;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1190px) {
    .chip-img {
        height: 8vh;
    }
    .img-div {
        height: max-content;
    }
    .reset-button-div {
        max-height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 576px) {

    .header-h3 {
        white-space: nowrap;
    }
    .chip-options-div {
        position: absolute;
        top: 70vh;
        left:-30vw;
        width: 60vh;
        height: 10vw;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        white-space: nowrap;
        z-index: 11;
    }

    .betting-options-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0px;
        padding-left: 10px;
        width: 100%;
    }

    .img-div {
        height: 10vmin;
        width: 10vmin;
    }
    
    .spin-img-div {
        height: 100%;
        width: 20vmin;
        z-index: 11;
    }

    .roulette-wheel {
        height: 15vh;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .roulette-wheel-spinning {
        height: 15vh;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .spin-circle {
        height: 10vh;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .winnings-div {
        height: 28vmin;
        width: 75vw;
    }

    .chip-count-text {
        font-size: 4vmin;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
  
@media only screen and (min-width: 330px) and (max-width: 436px) {
    .chip-options-div {
        width: 60vh;
        height: 10vw;
    }

    .betting-options-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 30px;
        width: 64vh;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        z-index: 2000;
        position: absolute;
        right:15vw;
        top: 46vh;
    }

    .img-div {
        height: 12vmin;
        width: 12vmin;
    }
    
    .spin-img-div {
        height: 100%;
        width: 8vmin;
    }

    .roulette-wheel {
        height: 100%;
        width: 12vh;
    }
    .roulette-wheel-spinning {
        height: 100%;
        width: 12vh;
    }

    .spin-circle {
        margin-top: 7vw;
        margin-left: 2vh;
        height: 6vh;
    }

    .winnings-div {
        position: absolute;
        top: -76vw;
        left: 0vh;
        height: 86vw;
        width: 70vh;
    }

    .chip-count-text {
        font-size: 4vmin;
    }
}

@media only screen and (min-width: 330px) and (max-width: 436px) and (orientation: portrait) {
    .chip-options-div {
        width: 60vw;
        height: 10vw;
    }

    .betting-options-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 30px;
        width: 64vh;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        z-index: 2000;
        position: absolute;
        right:15vw;
        top: 46vh;
    }

    .img-div {
        height: 12vmin;
        width: 12vmin;
    }
    
    .spin-img-div {
        height: 100%;
        width: 8vmin;
    }

    .roulette-wheel {
        height: 100%;
        width: 12vh;
    }
    .roulette-wheel-spinning {
        height: 100%;
        width: 12vh;
    }

    .spin-circle {
        margin-top: 7vw;
        margin-left: 2vh;
        height: 6vh;
    }

    .winnings-div {
        position: absolute;
        top: 4vh;
        left:-18vw;
        height: 20vh;
        width: 70vw;
    }

    .chip-count-text {
        font-size: 4vmin;
    }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {

}
  
@media only screen and (min-width: 768px) and (max-width: 1440px) {
    
}

@media only screen and (max-device-width: 812px) and (orientation: portrait) {
    .rotate {
      background: black;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 200px;
    }
    

    .phone {
      height: 50px;
      width: 100px;
      border: 3px solid white;
      border-radius: 10px;
      animation: rotate 1.5s ease-in-out infinite alternate;
      /* display: none; */
    }
    
    .message {
      color: white;
      font-size: 1em;
      margin-top: 40px;
      /* display: none; */
    }
    
    @keyframes rotate {
      0% {
        transform: rotate(0deg)
      }
      50% {
        transform: rotate(-90deg)
      }
      100% {
        transform: rotate(-90deg)
      }
    }
    
    .phone, .message {
      display: block;
    }

    .roulette-app {
      display: none;
    }
  }

  @media only screen and (max-device-height: 830px) and (orientation: landscape) {
    .roulette-btn-text {
        margin-bottom: 4vh;
        background-color: #D20202;
        width: 100%;
    }
  } 

//   @media only screen and (max-device-width: 667px) and (orientation: landscape) {
//     .desktop {
//       background: black;
//       margin-top: 60px;
//       margin-bottom: 60px;
//       height: 200px;
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-direction: column;
//     }
    
//     .phone {
//       height: 50px;
//       width: 100px;
//       border: 3px solid white;
//       border-radius: 10px;
//       animation: rotate 1.5s ease-in-out infinite alternate;
//     //   /* display: none; */
//     }
    
//     .message {
//       color: white;
//       font-size: 1em;
//       margin-top: 40px;
//       /* display: none; */
//     }
    
//     @keyframes rotate {
//       0% {
//         transform: rotate(0deg)
//       }
//       50% {
//         transform: rotate(-90deg)
//       }
//       100% {
//         transform: rotate(-90deg)
//       }
//     }
    
//     .phone, .message {
//       display: block;
//     }

//     .roulette-app {
//       display: none;
//     }
//   }