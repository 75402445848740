@use './scss/' as *;


@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Chakra Petch', sans-serif;
    // src:  url('../src/assets/font/icon/Furore.otf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
html {
    margin-right: 0 !important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: $txt-color;
    background-color: $bgr-body;
    position: relative;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
    transform: scale(1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    color: $white;
}
h1,
.h1 {
    font-size: 80px;
    line-height: 1.2;
}
h2,
.h2 {
    font-size: 64px;
    line-height: 1.25;
}
h3,
.h3 {
    font-size: 56px;
    line-height: 1.2;
}
h4,
.h4 {
    font-size: 44px;
    line-height: 1.25;
}
h5,
.h5 {
    font-size: 36px;
    line-height: 1.2;
}
h6,
.h6 {
    font-size: 18px;
    line-height: 1.33;
}

.h7,
.h8 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    color: $white;
}

.h7 {
    font-size: 24px;
    line-height: 1.33;  
    &.st {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
    }
}

.h8 {
    font-size: 20px;
    line-height: 1.3;  
    &.st {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
    }
}

.container {
    max-width: 1440px;
}

.z-index {
    z-index: 999;
}

#wrapper {
    width: $w100;
    height: $w100;
    overflow: hidden;
    position: relative;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}
ul, li {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
    transition: all .3s;
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: $color-hover;
        transition: all .3s;
    }
}

.center {
    text-align: center;
}

.container {
    width: 100%;
    margin: auto;
}
.container-fluid,
.container {
    padding-left: 15px;
    padding-right: 15px;
}

.row > * {
    padding-right: 15px;
    padding-left: 15px;
}

.wrap-fx-30 {
    @include flex2;
    margin-left: -30px;
    .icon-box {
        width: calc(33.3333% - 30px);
        margin-left: 30px;
    }
}
.no-pd-top {
    padding-top: 0;
}

.m-b17 {
    margin-bottom: 17px;
}

.p-b42 {
    padding-bottom: 42px;
}
.m-b40 {
    margin-bottom: 40px;
}
.m-r-40 {
    margin-right: -40px;
}

.p-l150 {
    padding-left: 150px;
}

.mt-30 {
    margin-top: 30px;
}
.mt-33 {
    margin-top: 33px;
}


.pl-70 {
    padding-left: 70px;
    @include desktop-width {
        padding-left: 0;
    }
}

.mt-48 {
    margin-top: 48px;
}

.mt--160 {
    margin-top: -160px !important;
}

.ml30-fx {
    margin-left: -30px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-39 {
    margin-bottom: 39px;
}

.mb-51 {
    margin-bottom: 51px;
}

.p-r60 {
    padding-right: 60%;
    @include tablet {
        padding-right: 0;
    }
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-49 {
    margin-bottom: 49px;
}
.mb-59 {
    margin-bottom: 59px;
}

.mb-61 {
    margin-bottom: 61px;
}
.mb-42 {
    margin-bottom: 42px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-58 {
    margin-bottom: 58px;
}

.mb-60 {
    margin-bottom: 60px;
}

.m-b6 {
    margin-bottom: 6px;
}

.m-t12 {
    margin-top: 12px;
}
.mt-48 {
    margin-top: 48px;
}

.m-b16 {
    margin-bottom: 16px;
}
.m-t16 {
    margin-top: 16px;
}
.mb-40 {
    margin-bottom: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.pl-r-0 {
    padding-left: 0 ;
    padding-right: 0 ;
}

.p-b23 {
    padding-bottom: 23px;
}

.boder-bt-st1 {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.txt-tranf-cap {
    text-transform: capitalize;
}

.m-t60 {
    margin-top: 60px;
}

.m-b50 {
    margin-bottom: 50px;
}

.m-b48 {
    margin-bottom: 48px;
}


.m-t40 {
    margin-top: 40px;
}

.pd20 {
    padding: 0 20%;
}

.pd22 {
    padding: 0 22%;
}

.mb-51 {
    margin-bottom: 51px;
}

.pd30 {
    padding: 0 30%;

    @include mobile {
        padding: 0;
    }
}

.bg-st1 {
    background: $bg-3 !important;
}

.bg-st2 {
    background: $bg-main !important;
}

.wrap-fx {
    @include flex2;
    flex-wrap: wrap;
}

.wrap-fx2 {
    @include flex(center,center);
    position: relative;
}

// form //
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"], 
input[type="tel"], 
input[type="color"] {
    border: 1px solid rgba(255,255,255,0.2);
    font-family: $font-main2;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 17px;
    line-height: 28px;
    border-radius: 0;
    padding: 13px 15px 13px 27px;
    height: 64px;
    width: 100%;
    background: transparent;
    color: $color-main2;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder, 
input[type="tel"]::placeholder, 
input[type="color"]::placeholder {
    font-family: $font-main2;
    color: $color-main2;
}




/* PreLoad
-------------------------------------------------------------- */
.preload {
    overflow: hidden;
} 

.preload-container {
    position  : relative;
    width     : 100%;
    height    : 100%;
    background: #fff;
    position  : fixed;
    top       : 0;
    bottom    : 0;
    right     : 0;
    left      : 0;
    z-index   : 99999999999;
    display   : block;
    overflow: hidden;
}

.preload-logo {
	position           : absolute;
	top                : 50%;
	left               : 50%;
	width              : 50px;
	height             : 50px;
	z-index            : 100;
	margin             : -30px 0 0 -30px;
	background: #ffffff;
	border-radius: 50%;
	background-image   : url('../src/assets/images/logo/preload.png');
	background-repeat  : no-repeat;
	background-position: center center;
	animation: 
	preload 1s linear infinite alternate;
	-webkit-animation-name:bounceIn; 
	animation-name:bounceIn; 
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
} 

.tf-section {  
    position: relative;
    padding: 80px 0;
    margin-bottom: 0 !important;
}


.desktopTeam {
    display: block;
}
.mobileTeam {
    display: none;
}


@media (max-width: 480px) {
    .desktopTeam {
        display: none;
    }
    .mobileTeam {
        display: block;
    }
    h3 {
        font-size: 8vw;
    }
}

@media only screen and (max-device-width: 767px) and (orientation: landscape) {
    h5 {
        margin-top:50%;
        font-size: 8vmin;
    }
}

.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.5em 1.5em 1.5em 1.5em;
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  ._modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: #f6f6f7;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._modal-close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .modal-body {
    padding-top: 0.25em;
  }
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
    margin-right: 17px;
  }

  .blackjack-app {
    .right-header {
        width: 28vw;
        margin-right: 0;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .lunar-list-container {
        height: 10vw;
    }

    .lunar-list {
        height: 8vw;
    }

    @media only screen and (orientation: landscape) {
        .lunar-list-container {
            height: 10vh;
        }
    
        .lunar-list {
            height: 8vh;
        }

        .bet-button {
            height: 8vh;
        }
        .tf-button-st2 {
            height:8vh;
            padding: 4px 8px;
            border-radius: 5px;
        }
        #ready-to-start,
        #not-ready-to-start {
            margin-bottom: 14px;
        }

        .betting-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media only screen and (max-devide-width: 767px) and (orientation: portrait) {

        .header-h3 {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 1vw;
        }

        .lunar-list-container {
            height: 8vw;
            align-items: center;
        }

        .blackjack-chip-count-text {
            font-size: 4vmin;
        }
    
        .header-h3 {
            font-size: 4vmin;
        }
        .right-header {
            width: 28vw;
        }
        .lunar-list {
            height: 6vw;
        }

        .bet-button {
            height: 6vw;
        }
        .tf-button-st2 {
            height:8vh;
            padding: 4px 8px;
            border-radius: 5px;
        }
        #ready-to-start,
        #not-ready-to-start {
            margin-bottom: 14px;
        }

        .betting-options {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
  }
  .shadowfilter {
    -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    }

  .shadowfilter:hover {
    -webkit-filter: drop-shadow(2px 2px 3px rgba(255, 208, 0, 0.609));
    filter: drop-shadow(2px 2px 3px rgba(254, 207, 0, 0.628));
  }
  

  .lunar-list-container {
    height: max-content;
    margin-right: 12px;
    width: auto;
    margin-left: auto;
    margin-top: 4px;
    z-index: 4;
  }

  .lunar-list-container:hover {
    cursor: pointer;
    border: 2px rgba(255, 217, 0, 0) solid;
  }

  .lunar-list {
    height: 8vh;
  }

  .lunar-list:hover {
    border: 2px rgba(255, 217, 0, 0) solid;
  }

  .error-message {
    margin-top: 10px;
    color: #e23636;
    font-style: oblique;
  }

  .success-message {
    margin-top: 10px;
    color: #82dd55;
    font-style: oblique;
  }

  @media only screen and (max-device-width: 844) and (orientation: landscape) {
    .blackjack-rotate {
      background: black;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    .blackjack-phone {
      height: 50px;
      width: 100px;
      border: 3px solid white;
      border-radius: 10px;
      animation: rotate 1.5s ease-in-out infinite alternate;
      /* display: none; */
    }
    
    .blackjack-message {
      color: white;
      font-size: 1em;
      margin-top: 40px;
      /* display: none; */
    }
    
    @keyframes rotate {
      0% {
        transform: rotate(0deg)
      }
      50% {
        transform: rotate(-90deg)
      }
      100% {
        transform: rotate(-90deg)
      }
    }
    
    .blackjack-phone, .blackjack-message {
      display: block;
    }

    .blackjack-app {
      display: none;
    }
  }